import React from "react";
import babyImage from "../assets/babycare.png";
import careImage from "../assets/caregiver.png";
import nurseImage from "../assets/nursing.png";

// ServiceCard Component
const ServiceCard = ({ title, description, image, gradientFrom }) => {
  return (
    <div className="service-card w-[380px] h-[270px] group relative bg-white shadow-lg rounded-3xl sm:w-[440px] sm:h-[310px]">
      {/* Image */}
      <div className="absolute right-0 w-full flex justify-end items-center mr-5 h-full">
        <img
          src={image}
          alt={title}
          className="absolute right-0 h-full w-full object-contain object-right transition-transform duration-300 group-hover:scale-110"
          style={{ objectPosition: "right center" }}
        />
      </div>

      {/* Gradient overlay */}
      <div
        className="absolute inset-0 transition-opacity duration-300 group-hover:opacity-0 rounded-3xl"
        style={{
          background: `linear-gradient(180deg, ${gradientFrom}99, ${gradientFrom})`,
        }}
      ></div>

      {/* Text content container */}
      <div className="absolute inset-0 flex flex-col items-center justify-center z-10">
        <div className="flex flex-col items-center mt-auto mb-12">
          <h2 className="font-bold text-3xl sm:text-4xl md:text-5xl lg:text-5xl text-white drop-shadow-lg mb-2 sm:mb-4">
            {title}
          </h2>
          <p className="text-sm sm:text-lg md:text-xl lg:text-xl text-white text-center px-3 sm:px-4 md:px-6 max-w-[95%] sm:max-w-[90%] leading-relaxed font-medium tracking-wide drop-shadow-xl">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

function Services() {
  const servicesData = [
    {
      title: "Nursing",
      description:
        "Our nursing services provide expert care for patients at home, including Wound Care, (IV) Therapy, and Medication Administration.",
      image: nurseImage,
      gradientFrom: "#F6A226",
    },
    {
      title: "Caregiver",
      description:
        "Our caregivers provide personalized assistance for patients who need help with daily living activities, ensuring comfort and well-being at home.",
      image: careImage,
      gradientFrom: "#F0592A",
    },
    {
      title: "Baby Care",
      description:
        "We offer specialized baby care services at home, providing nurturing support for newborns and infants with a focus on safety and development.",
      image: babyImage,
      gradientFrom: "#009247",
    },
  ];

  return (
    <div className="servicesbg">
      <div className="services relative flex flex-row flex-wrap justify-center gap-8 px-4 md:px-8">
        {servicesData.map((service, index) => (
          <ServiceCard
            key={index}
            title={service.title}
            description={service.description}
            image={service.image}
            gradientFrom={service.gradientFrom}
          />
        ))}
      </div>
    </div>
  );
}

export default Services;
