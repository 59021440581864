import React, { useEffect, useState } from 'react';
import axios from 'axios';

const CareRecipientForms = () => {
  const [forms, setForms] = useState([]);

  // Fetch data from the backend
  useEffect(() => {
    axios
      .get('http://localhost:5000/api/landing/admin/customers') // Adjust URL as per your API route
      .then((response) => {
        if (response.data.success) {
          setForms(response.data.data);
        } else {
          console.error('Failed to fetch forms:', response.data.message);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h1>Care Recipient Forms</h1>
      {forms.length > 0 ? (
        <table border="1" cellPadding="10" style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Contact Number</th>
              <th>Email</th>
              <th>Service Details</th>
              <th>Address Information</th>
              <th>Terms Accepted</th>
              <th>Submitted On</th>
            </tr>
          </thead>
          <tbody>
            {forms.map((form) => (
              <tr key={form._id}>
                <td>{form.fullName}</td>
                <td>{form.contactNumber}</td>
                <td>{form.email || 'N/A'}</td>
                <td>
                  <strong>Service:</strong> {form.serviceDetails?.selectedService || 'N/A'}
                  <br />
                  <strong>Date:</strong>{' '}
                  {form.serviceDetails?.preferredDateTime
                    ? new Date(form.serviceDetails.preferredDateTime).toLocaleString()
                    : 'N/A'}
                  <br />
                  <strong>Duration:</strong> {form.serviceDetails?.duration || 'N/A'}
                  <br />
                  <strong>Requirements:</strong> {form.serviceDetails?.specificRequirements || 'N/A'}
                </td>
                <td>
                  {form.addressInformation ? (
                    <>
                      <strong>House/Name:</strong> {form.addressInformation.houseNumberName || 'N/A'}
                      <br />
                      <strong>Street:</strong> {form.addressInformation.street || 'N/A'}
                      <br />
                      <strong>Landmark:</strong> {form.addressInformation.landmark || 'N/A'}
                      <br />
                      <strong>City:</strong> {form.addressInformation.city || 'N/A'}
                      <br />
                      <strong>State:</strong> {form.addressInformation.state || 'N/A'}
                      <br />
                      <strong>Zip Code:</strong> {form.addressInformation.zipCode || 'N/A'}
                    </>
                  ) : (
                    'N/A'
                  )}
                </td>
                <td>{form.confirmationAgreement?.termsAccepted ? 'Yes' : 'No'}</td>
                <td>{new Date(form.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No forms available.</p>
      )}
    </div>
  );
};

export default CareRecipientForms;
