import React, { useState } from "react";
import axios from "axios";
import logo1 from "../assets/svvasthya_logo.svg";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    contactType: ""
  });
  const [submitStatus, setSubmitStatus] = useState({
    success: false,
    error: false,
    message: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await axios.post('http://localhost:5000/api/landing/contactform', formData);
      
      setSubmitStatus({
        success: true,
        error: false,
        message: response.data.message
      });
      
      // Reset form after successful submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
        contactType: ""
      });
    } catch (error) {
      setSubmitStatus({
        success: false,
        error: true,
        message: error.response?.data?.error || "An error occurred. Please try again."
      });
    }
  };

  return (
    <section className="bg-gradient-to-r from-[#ef5b2a1a] to-[#03a3491a] text-black py-8 md:py-12 ">
      <h3 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl text-center font-bold mb-6 sm:mb-8 md:mb-10 text-[#262163] px-4 pt-10">
        Contact Us
      </h3>
      <div className="container mx-auto px-4 md:px-12">
        <div className="grid md:grid-cols-2 gap-8 md:gap-12">
          {/* Left side: Company Logo and Address */}
          <div className="flex flex-col items-center justify-center space-y-4 md:space-y-6 p-4 md:p-8 mr-10">
            <img alt="Company Logo" src={logo1} className="w-28 md:w-36 mb-4" />
            <p className="text-lg text-gray-700">
              <strong className="text-[#262163] text-xl mb-2 block">
                Our Office
              </strong>
              <span className="block mb-1">1234 Business Ave, Suite 500</span>
              <span className="block mb-1">City, State, Zip Code</span>
              <span className="block mb-1">Phone: (123) 456-7890</span>
              <span className="block">Email: info@company.com</span>
            </p>
          </div>

          {/* Right side: Contact Form */}
          <div className="bg-white text-black p-6 md:p-8 rounded-lg shadow-lg w-full">
            {submitStatus.success && (
              <div className="bg-green-100 text-green-800 p-4 rounded mb-4">
                {submitStatus.message}
              </div>
            )}
            {submitStatus.error && (
              <div className="bg-red-100 text-red-800 p-4 rounded mb-4">
                {submitStatus.message}
              </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-6">
              <input
                type="text"
                name="name"
                placeholder="Full Name"
                value={formData.name}
                onChange={handleChange}
                required
                className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
              />

              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
                />
                <input
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
                />
              </div>

              <input
                type="text"
                name="subject"
                placeholder="Subject"
                value={formData.subject}
                onChange={handleChange}
                required
                className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
              />

              <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                {["Business Inquiry", "Support", "General Inquiry"].map(
                  (type) => (
                    <div key={type}>
                      <label
                        htmlFor={type.replace(/\s+/g, "")}
                        className="block w-full cursor-pointer rounded-lg border border-gray-300 p-3 text-gray-600 hover:border-[#EF5A2A] hover:text-[#EF5A2A] transition-colors"
                      >
                        <input
                          className="sr-only"
                          id={type.replace(/\s+/g, "")}
                          type="radio"
                          name="contactType"
                          value={type}
                          checked={formData.contactType === type}
                          onChange={handleChange}
                          required
                        />
                        <span className="text-sm font-medium">{type}</span>
                      </label>
                    </div>
                  )
                )}
              </div>

              <textarea
                name="message"
                placeholder="Your Message"
                rows="6"
                value={formData.message}
                onChange={handleChange}
                required
                className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
              ></textarea>

              <button
                type="submit"
                className="w-full bg-[#EF5A2A] text-white font-medium py-3 rounded-lg hover:bg-opacity-90 transition-colors"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;