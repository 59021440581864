import React from "react";
// import "./App.css";
import Booknow from "./components/Booknow";
import ComingSoon from "./components/ComingSoon";
import ContactUs from "./components/ContactUs";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Services from "./components/Services";
function Home() {
  return (
    <>
      <Navbar />
      <div id="home">
        <Hero />
      </div>
      <Services />
      <div id="booknow">
        <Booknow />
      </div>
      <div id="comingsoon">
        <ComingSoon />
      </div>
      <div id="contact">
        <ContactUs />
      </div>
    </>
  );
}

export default Home;
