import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import CareRecipientForms from './CareRecipientForms';
import SvvasthyaSaathi from './SvvasthyaSaathi';
import ContactUs from './ContactUs';

const AdminDash = () => {
  return (
    <div style={{ display: 'flex', height: '100vh' }}>
      {/* Sidebar Navigation */}
      <nav
        style={{
          width: '250px',
          backgroundColor: '#f4f4f4',
          padding: '20px',
          boxShadow: '2px 0 5px rgba(0, 0, 0, 0.1)',
        }}
      >
        <h2>Admin Dashboard</h2>
        <ul style={{ listStyleType: 'none', padding: 0 }}>
          <li>
            <Link to="care-recipient-forms">Care Recipient Forms</Link>
          </li>
          <li>
            <Link to="svvasthya-saathi">Svvasthya Saathi</Link>
          </li>
          <li>
            <Link to="contact-us">Contact Us</Link>
          </li>
        </ul>
      </nav>

      {/* Main Content Area */}
      <div style={{ flex: 1, padding: '20px' }}>
        <Routes>
          <Route path="care-recipient-forms" element={<CareRecipientForms />} />
          <Route path="svvasthya-saathi" element={<SvvasthyaSaathi />} />
          <Route path="contact-us" element={<ContactUs />} />
        </Routes>
      </div>
    </div>
  );
};

export default AdminDash;
