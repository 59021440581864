import React, { useEffect, useState } from 'react';
import axios from 'axios';

const ContactUs = () => {
  const [contacts, setContacts] = useState([]);

  // Fetch data from the backend
  useEffect(() => {
    axios
      .get('http://localhost:5000/api/landing/admin/contactus') 
      .then((response) => {
        if (response.data.success) {
          setContacts(response.data.data);
        } else {
          console.error('Failed to fetch data:', response.data.message);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h1>Contact Us Submissions</h1>
      {contacts.length > 0 ? (
        <table border="1" cellPadding="10" style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Subject</th>
              <th>Message</th>
              <th>Contact Type</th>
              <th>Submitted At</th>
            </tr>
          </thead>
          <tbody>
            {contacts.map((contact) => (
              <tr key={contact._id}>
                <td>{contact.name || 'N/A'}</td>
                <td>{contact.email || 'N/A'}</td>
                <td>{contact.phone || 'N/A'}</td>
                <td>{contact.subject || 'N/A'}</td>
                <td>{contact.message || 'N/A'}</td>
                <td>{contact.contactType || 'N/A'}</td>
                <td>{new Date(contact.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No submissions available.</p>
      )}
    </div>
  );
};

export default ContactUs;

