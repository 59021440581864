import React from 'react';
import './App.css';
import Home from './Home';
import AdminDash from './components/Admin/AdminDash';
import Login from './components/Admin/Login';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <Router>
      <div className="App">
        {/* Define routes */}
        <Routes>
          {/* Route for the landing page */}
          <Route path="/" element={<Home />} />

          {/* Route for the admin dashboard */}
          <Route path="/admin/*" element={<AdminDash />} />
          <Route path="/Adminlogin" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
