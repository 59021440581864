import React, { useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import axios from "axios";

function BookNowForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedForm, setSelectedForm] = useState(null);
  const [formData, setFormData] = useState({
    patient: {
      fullName: "",
      contactNumber: "",
      email: "",
      serviceDetails: {
        selectedService: "",
        preferredDateTime: "",
        duration: "",
        specificRequirements: ""
      },
      addressInformation: {
        houseNumberName: "",
        street: "",
        landmark: "",
        city: "",
        state: "",
        zipCode: ""
      },
      confirmationAgreement: {
        termsAccepted: false
      }
    },
    svvasthyaSathi: {
      personalInformation: {
        fullName: "",
        contactNumber: "",
        email: ""
      },
      professionalInformation: {
        role: "",
        yearsOfExperience: "",
        qualifications: "",
        workExperience: "",
        preferredWorkingHours: ""
      },
      preferredLocations: "",
      confirmationAgreement: {
        termsAccepted: false
      }
    }
  });

  const handleBookNowClick = (type) => {
    setSelectedForm(type);
    setIsModalOpen(true);
  };

  const handleInputChange = (e, section, subsection = null) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevData => {
      const updatedData = { ...prevData };
      if (subsection) {
        updatedData[section][subsection][name] = type === 'checkbox' ? checked : value;
      } else {
        updatedData[section][name] = type === 'checkbox' ? checked : value;
      }
      return updatedData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const endpoint = selectedForm === 'patient'
        ? 'http://localhost:5000/api/landing/customerform'
        : 'http://localhost:5000/api/landing/sathiform';

      const dataToSubmit = selectedForm === 'patient'
        ? formData.patient
        : formData.svvasthyaSathi;

      const response = await axios.post(endpoint, dataToSubmit);
      alert(response.data.message);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Form submission error:', error);
      alert(error.response?.data?.message || 'Form submission failed');
    }
  };

  const renderPatientForm = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <input
        type="text"
        name="fullName"
        placeholder="Full Name"
        value={formData.patient.fullName}
        onChange={(e) => handleInputChange(e, 'patient')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
        required
      />
      <input
        type="tel"
        name="contactNumber"
        placeholder="Contact Number"
        value={formData.patient.contactNumber}
        onChange={(e) => handleInputChange(e, 'patient')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.patient.email}
        onChange={(e) => handleInputChange(e, 'patient')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
      />

      <select
        name="selectedService"
        value={formData.patient.serviceDetails.selectedService}
        onChange={(e) => handleInputChange(e, 'patient', 'serviceDetails')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors text-gray-600"
        required
      >
        <option value="">Select Service</option>
        <option value="Nursing">Nursing</option>
        <option value="Caregiver">Caregiver</option>
        <option value="Baby Care">Baby Care</option>
      </select>

      <input
        type="datetime-local"
        name="preferredDateTime"
        value={formData.patient.serviceDetails.preferredDateTime}
        onChange={(e) => handleInputChange(e, 'patient', 'serviceDetails')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors text-gray-600"
        required
      />

      <select
        name="duration"
        value={formData.patient.serviceDetails.duration}
        onChange={(e) => handleInputChange(e, 'patient', 'serviceDetails')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors text-gray-600"
        required
      >
        <option value="">Select Duration</option>
        <option value="Hourly">Hourly</option>
        <option value="Daily">Daily</option>
        <option value="Weekly">Weekly</option>
      </select>

      <textarea
        name="specificRequirements"
        placeholder="Specific Requirements"
        value={formData.patient.serviceDetails.specificRequirements}
        onChange={(e) => handleInputChange(e, 'patient', 'serviceDetails')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#EF5A2A] focus:ring-1 focus:ring-[#EF5A2A] transition-colors"
        rows="3"
      />

      <div className="flex items-center">
        <input
          type="checkbox"
          name="termsAccepted"
          checked={formData.patient.confirmationAgreement.termsAccepted}
          onChange={(e) => handleInputChange(e, 'patient', 'confirmationAgreement')}
          className="mr-2 h-4 w-4 rounded border-gray-300 text-[#EF5A2A] focus:ring-[#EF5A2A]"
          required
        />
        <label className="text-sm text-gray-600">I accept the Terms & Conditions</label>
      </div>

      <button
        type="submit"
        className="w-full bg-[#EF5A2A] text-white font-medium py-3 rounded-lg hover:bg-opacity-90 transition-colors"
      >
        Submit Patient Request
      </button>
    </form>
  );

  const renderSvvasthyaSaathiForm = () => (
    <form onSubmit={handleSubmit} className="space-y-6">
      <input
        type="text"
        name="fullName"
        placeholder="Full Name"
        value={formData.svvasthyaSathi.personalInformation.fullName}
        onChange={(e) => handleInputChange(e, 'svvasthyaSathi', 'personalInformation')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#262163] focus:ring-1 focus:ring-[#262163] transition-colors"
        required
      />
      <input
        type="tel"
        name="contactNumber"
        placeholder="Contact Number"
        value={formData.svvasthyaSathi.personalInformation.contactNumber}
        onChange={(e) => handleInputChange(e, 'svvasthyaSathi', 'personalInformation')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#262163] focus:ring-1 focus:ring-[#262163] transition-colors"
        required
      />
      <input
        type="email"
        name="email"
        placeholder="Email"
        value={formData.svvasthyaSathi.personalInformation.email}
        onChange={(e) => handleInputChange(e, 'svvasthyaSathi', 'personalInformation')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#262163] focus:ring-1 focus:ring-[#262163] transition-colors"
        required
      />

      <select
        name="role"
        value={formData.svvasthyaSathi.professionalInformation.role}
        onChange={(e) => handleInputChange(e, 'svvasthyaSathi', 'professionalInformation')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#262163] focus:ring-1 focus:ring-[#262163] transition-colors text-gray-600"
        required
      >
        <option value="">Select Role</option>
        <option value="Nurse">Nurse</option>
        <option value="Caregiver">Caregiver</option>
        <option value="Baby Care Specialist">Baby Care Specialist</option>
      </select>

      <input
        type="number"
        name="yearsOfExperience"
        placeholder="Years of Experience"
        value={formData.svvasthyaSathi.professionalInformation.yearsOfExperience}
        onChange={(e) => handleInputChange(e, 'svvasthyaSathi', 'professionalInformation')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#262163] focus:ring-1 focus:ring-[#262163] transition-colors"
      />

      <input
        type="text"
        name="qualifications"
        placeholder="Qualifications"
        value={formData.svvasthyaSathi.professionalInformation.qualifications}
        onChange={(e) => handleInputChange(e, 'svvasthyaSathi', 'professionalInformation')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#262163] focus:ring-1 focus:ring-[#262163] transition-colors"
      />

      <select
        name="preferredWorkingHours"
        value={formData.svvasthyaSathi.professionalInformation.preferredWorkingHours}
        onChange={(e) => handleInputChange(e, 'svvasthyaSathi', 'professionalInformation')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#262163] focus:ring-1 focus:ring-[#262163] transition-colors text-gray-600"
      >
        <option value="">Preferred Working Hours</option>
        <option value="Full-time">Full-time</option>
        <option value="Part-time">Part-time</option>
        <option value="Flexible">Flexible</option>
      </select>

      <input
        type="text"
        name="preferredLocations"
        placeholder="Preferred Locations"
        value={formData.svvasthyaSathi.preferredLocations}
        onChange={(e) => handleInputChange(e, 'svvasthyaSathi')}
        className="w-full rounded-lg border border-gray-300 p-3 text-sm focus:border-[#262163] focus:ring-1 focus:ring-[#262163] transition-colors"
        required
      />

      <div className="flex items-center">
        <input
          type="checkbox"
          name="termsAccepted"
          checked={formData.svvasthyaSathi.confirmationAgreement.termsAccepted}
          onChange={(e) => handleInputChange(e, 'svvasthyaSathi', 'confirmationAgreement')}
          className="mr-2 h-4 w-4 rounded border-gray-300 text-[#262163] focus:ring-[#262163]"
          required
        />
        <label className="text-sm text-gray-600">I accept the Terms & Conditions</label>
      </div>

      <button
        type="submit"
        className="w-full bg-[#262163] text-white font-medium py-3 rounded-lg hover:bg-opacity-90 transition-colors"
      >
        Submit Svvasthya Sathi Application
      </button>
    </form>
  );

  return (
    <div className="flex flex-col items-center gap-6 p-4 md:p-8 bg-gradient-to-r from-[#ef5b2a1a] to-[#03a3491a]">
      <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 p-4 md:p-8">
        Book Now
      </h1>

      <div className="flex flex-col md:flex-row justify-center items-stretch gap-6 w-full max-w-7xl mx-auto">
        {/* Card for Patient */}
        <div className="card bg-[#EF5A2A] text-white border rounded-lg shadow-lg p-4 md:p-6 w-full md:w-1/2 flex flex-col justify-between gap-4 min-h-[400px]">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold text-center mb-4">
            Patient
          </h2>
          <p className="text-base sm:text-lg md:text-xl text-center">
            Access professional healthcare services at your convenience.
            Whether you need nursing, caregiving, or baby care, Svvasthya ensures reliable
            and compassionate support tailored to your needs. Simply click "Book Now,"
            fill out the form, and we'll provide you the best care in real-time.
          </p>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => handleBookNowClick("patient")}
              className="text-[#EF5A2A] font-bold text-lg sm:text-xl h-12 w-40 bg-white py-2 px-4 rounded-3xl hover:bg-opacity-90 transition"
            >
              Book Now
            </button>
          </div>
        </div>

        {/* Card for Swastya Sathi */}
        <div className="card bg-[#262163] text-white border rounded-lg shadow-lg p-6 w-full md:w-1/2 lg:w-[45%] flex flex-col justify-between gap-4 min-h-[300px]">
          <h2 className="text-2xl sm:text-3xl md:text-4xl font-semibold text-center mb-4">
            Svvastya Saathi
          </h2>
          <p className="text-base sm:text-lg md:text-xl text-center">
            Are you a skilled nurse, caregiver, or baby care specialist? Become a part of the Svvasthya Saathi family
            and make a difference in people’s lives while growing your career. Enjoy flexible schedules,
            real-time assignments, and the opportunity to earn competitive rewards. Click "Join Us," fill out the form,
            and start your journey with Svvasthya today.
          </p>
          <div className="flex justify-center mt-4">
            <button
              onClick={() => handleBookNowClick("svvasthyaSathi")}
              className="text-[#262163] font-bold text-lg sm:text-xl h-12 w-40 bg-white py-2 px-4 rounded-3xl hover:bg-opacity-90 transition"
            >
              Join Us
            </button>
          </div>
        </div>
      </div>

      {/* Contact Form Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center z-50 p-4">
          <div className="relative bg-white p-4 md:p-8 rounded-lg shadow-lg w-full max-w-lg mx-4">
            <button
              onClick={() => setIsModalOpen(false)}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <XMarkIcon className="h-6 w-6" />
            </button>

            <h3 className="text-xl sm:text-2xl md:text-3xl text-center font-semibold text-gray-800 mb-6">
              {selectedForm === "patient"
                ? "Patient Request"
                : "Svvasthya Sathi Application"}
            </h3>

            {/* Scrollable content */}
            <div className="max-h-[70vh] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200">
              {selectedForm === "patient"
                ? renderPatientForm()
                : renderSvvasthyaSaathiForm()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BookNowForm;