import React, { useEffect, useState } from 'react';
import axios from 'axios';

const SvvasthyaSaathi = () => {
  const [saathis, setSaathis] = useState([]);

  // Fetch data from the backend
  useEffect(() => {
    axios
      .get('http://localhost:5000/api/landing/admin/saathis') // Adjust URL as per your API route
      .then((response) => {
        if (response.data.success) {
          setSaathis(response.data.data);
        } else {
          console.error('Failed to fetch data:', response.data.message);
        }
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  return (
    <div>
      <h1>Svvasthya Saathi List</h1>
      {saathis.length > 0 ? (
        <table border="1" cellPadding="10" style={{ borderCollapse: 'collapse', width: '100%' }}>
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Contact Number</th>
              <th>Email</th>
              <th>Professional Information</th>
              <th>Preferred Location</th>
              <th>Terms Accepted</th>
              <th>Joined On</th>
            </tr>
          </thead>
          <tbody>
            {saathis.map((saathi) => (
              <tr key={saathi._id}>
                <td>{saathi.personalInformation?.fullName || 'N/A'}</td>
                <td>{saathi.personalInformation?.contactNumber || 'N/A'}</td>
                <td>{saathi.personalInformation?.email || 'N/A'}</td>
                <td>
                  <strong>Role:</strong> {saathi.professionalInformation?.role || 'N/A'}
                  <br />
                  <strong>Experience:</strong>{' '}
                  {saathi.professionalInformation?.yearsOfExperience !== undefined
                    ? `${saathi.professionalInformation.yearsOfExperience} years`
                    : 'N/A'}
                  <br />
                  <strong>Qualifications:</strong> {saathi.professionalInformation?.qualifications || 'N/A'}
                  <br />
                  <strong>Work Experience:</strong> {saathi.professionalInformation?.workExperience || 'N/A'}
                  <br />
                  <strong>Working Hours:</strong> {saathi.professionalInformation?.preferredWorkingHours || 'N/A'}
                </td>
                <td>{saathi.preferredLocations || 'N/A'}</td>
                <td>{saathi.confirmationAgreement?.termsAccepted ? 'Yes' : 'No'}</td>
                <td>{new Date(saathi.createdAt).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
};

export default SvvasthyaSaathi;
