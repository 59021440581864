import React, { useEffect } from "react";
import textlogo from "../assets/svvasthya_letter_logo.svg";
import watermark from "../assets/watermark.svg";

function Hero() {
  useEffect(() => {
    const timer1 = setTimeout(() => {
      const svvasthyaElement = document.querySelector(".hero-svvasthya");
      if (svvasthyaElement) {
        svvasthyaElement.style.display = "flex";
      }
    }, 1000);

    const timer2 = setTimeout(() => {
      const svvasthyaElement2 = document.querySelector(".hero-content");
      if (svvasthyaElement2) {
        svvasthyaElement2.style.display = "block";
      }
    }, 2500);

    const timer3 = setTimeout(() => {
      const svvasthyaElement3 = document.querySelector(".hero-svvasthya");
      if (svvasthyaElement3) {
        svvasthyaElement3.style.display = "none";
      }
    }, 3500);

    const timer4 = setTimeout(() => {
      const svvasthyaElement4 = document.querySelector(".services");
      if (svvasthyaElement4) {
        svvasthyaElement4.style.display = "flex";
      }
    }, 3500);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, []);

  return (
    <div className="hero max-h-[50vh] md:max-h-[60vh] lg:max-h-[70vh] w-full px-4 md:px-8 lg:px-12">
      <div className="hero-background">
        <img
          src={watermark}
          alt="Watermark"
          className="watermark w-[90%] md:w-[80%] lg:w-[70%] opacity-90"
        />
      </div>
      <div className="hero-svvasthya">
        <img
          src={textlogo}
          alt="textlogo"
          className="textlogo w-[80%] sm:w-[70%] md:w-[60%] lg:w-[55%] max-w-3xl"
        />
      </div>
      <div className="hero-content relative w-full max-w-4xl mx-auto">
        <div className="space-y-4 md:space-y-6">
          <div className="text-center">
            <p className="heading text-[#262163] text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold">
              स्वास्थ्य का सच्चा साथी:
            </p>
            <h1 className="heading-two font-bold text-[24px] sm:text-[32px] md:text-[40px] lg:text-[48px] xl:text-[60px] mt-2 md:mt-4 leading-tight">
              Empowering Your Well-being at Home
            </h1>
          </div>
          <div className="text-center mt-4 md:mt-6">
            <p className="font-normal text-lg sm:text-xl md:text-2xl">
              Bringing Compassionate
            </p>
            <p className="font-normal text-lg sm:text-xl md:text-2xl">
              Home Health care to your Doorstep
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
