import React, { useEffect, useState } from "react";
import comingSoonImage from "../assets/Devices mockups (Community).png";

const MovingText = ({ text }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [direction, setDirection] = useState({ x: Math.random() * 2 - 1, y: Math.random() * 2 - 1 });

  useEffect(() => {
    const moveText = () => {
      setPosition(prev => {
        let newX = prev.x + direction.x * 2;
        let newY = prev.y + direction.y * 2;

        // Bounce off the edges of the container
        if (newX < -200 || newX > 200) {
          setDirection(prev => ({ ...prev, x: -prev.x }));
          newX = prev.x;
        }
        if (newY < -200 || newY > 200) {
          setDirection(prev => ({ ...prev, y: -prev.y }));
          newY = prev.y;
        }

        return { x: newX, y: newY };
      });
    };

    const interval = setInterval(moveText, 50);
    return () => clearInterval(interval);
  }, [direction]);

  return (
    <div
      className="text-sm md:text-base font-medium text-gray-700"
      style={{
        transform: `translate(${position.x}px, ${position.y}px)`,
        transition: 'transform 0.05s linear'
      }}
    >
      {text}
    </div>
  );
};

const ComingSoon = () => {
  const features = [
    "Real-time Analytics",
    "Cloud Integration",
    "Mobile Responsive",
    "Smart Dashboard",
    "Secure Platform",
    "24/7 Support",
    "Custom Reports",
    "API Access"
  ];

  return (
    <div className="flex flex-col items-center justify-center bg-gradient-to-r from-[#ef5b2a1a] to-[#03a3491a] text-black ">
      <div className="mt-20 container mx-auto text-center">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold mb-6 sm:mb-8 md:mb-10 text-[#262163] px-4">
          Coming Soon
        </h2>
        <div className="flex justify-center items-center w-full">
          <div className="w-full">
            <img
              src={comingSoonImage}
              alt="Coming Soon"
              className="w-full h-auto backdrop-blur-md mix-blend-multiply object-cover"
            />
          </div>
        </div>
        {/* <div className="mt-8 sm:mt-10 md:mt-12 text-center px-4">
          <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-medium text-gray-800 animate__animated animate__fadeIn animate__delay-2s">
            Stay tuned for updates and more information!
          </p>
        </div> */}

        {/* Features and functionalities */}
        <div className="text-[#262163] py-12 px-4 md:px-16">
          {/* Header Section */}
          <div className="text-center mb-10">
            <h1 className="text-4xl md:text-6xl font-bold animate-fade-in-up">
              Upcoming Features
            </h1>
            <p className="mt-4 text-lg text-[#EF5A2A] md:text-xl animate-fade-in-up delay-200">
              Discover the features designed to revolutionize home healthcare for both
              patients and attendants.
            </p>
          </div>

          {/* Features Section */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {/* Customer Side Features */}
            <div className="animate-pulse-scale">
              <h2 className="text-2xl md:text-3xl font-semibold mb-4">
                Patient Side - Web App
              </h2>
              <ul className="space-y-2 text-lg">
                {[
                  "Real-Time Booking System",
                  "Customized Care Services",
                  "Support When You Need It",
                  "Service Selection and Booking",
                  "Comprehensive Profile Management",
                  "Health Record System",
                  "Appointments Management",
                  "Real-Time Updates",
                  "In-App Support",
                  "Subscription Plans",
                  "Secure Payments",
                  "Flexible Scheduling",
                  "Access to Verified Attendants",
                ].map((feature, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-2 before:content-['✔'] before:text-yellow-300"
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </div>

            {/* Attendant Side Features */}
            <div className="animate-pulse-scale">
              <h2 className="text-2xl md:text-3xl font-semibold mb-4">
                Attendant Side - Mobile App
              </h2>
              <ul className="space-y-2 text-lg">
                {[
                  "Real-Time Availability Management",
                  "Appointment Management",
                  "Profile Management",
                  "Job Matching and Assignments",
                  "Real-Time Alerts",
                  "Earnings and Payments",
                  "24/7 Support and Resources",
                  "Progressive Career Growth",
                  "Access to Training and Resources",
                  "AI-Driven Job Assignments",
                  "Flexible Scheduling",
                  "Onboarding Verification and Screening",
                ].map((feature, index) => (
                  <li
                    key={index}
                    className="flex items-center gap-2 before:content-['✔'] before:text-yellow-300"
                  >
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* Coming Soon Section */}
          <div className="mt-16 text-center">
            <h3 className="text-3xl text-[#EF5A2A] md:text-4xl font-bold mb-4">
              Stay Tuned for Something Amazing!
            </h3>
            <p className="text-lg md:text-xl">
             Svvasthya is launching soon to transform home healthcare experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
